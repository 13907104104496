import React from 'react';
import { Link } from 'react-router-dom';
import ContactIcons from '../Contact/ContactIcons';

// const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        {/* <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" /> */}
      </Link>
      <header>
        <h2>Junyu Yan</h2>
        <p>Actively seeking full-time software engineer opportunities!</p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Junyu. I am a proud alumnus of both USC, where
        I completed my Master&apos;s degree, and MU, where I earned my Bachelor&apos;s
        degree in Computer Science.
      </p>
      <p>
        I am actively seeking full-time software engineer opportunities!
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section>
      <ContactIcons />
    </section>
  </section>
);

export default SideBar;
